import React, { useState, useRef, useEffect } from 'react';
import { PageProps } from 'gatsby';
import SanitizeHtml from 'sanitize-html';
import qs from 'qs';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import * as EmailValidator from 'email-validator';
import ReCAPTCHA from 'react-google-recaptcha';

import Layout, { LayoutOptions, HeroSectionColors } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import PageMenu from '../../components/PageMenu/PageMenu';
import contactUsMenu from '../../data/page-menus/contact-us';
import Input, { InputType } from '../../components/primitives/Input/Input';
import Button from '../../components/primitives/Button/Button';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import NativeSelect, { SelectOption } from '../../components/primitives/NativeSelect/NativeSelect';
import TextArea from '../../components/primitives/TextArea/TextArea';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import CommonData from '../../data/common/common';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import RouteKeys from '../../../config/RouteKeys';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import { ButtonLinkSize } from '../../components/ButtonLink/ButtonLink';
import Link from '../../components/Link/Link';
import { useLocaleContext } from '../../context/Locale';
import { IconTypes } from '../../components/Icon/Icon';
import './style.scss';

type Props = PageProps & WithTranslation;

enum InputErrorType {
  EMPTY = 'EMPTY',
  INVALID_CHARACTERS = 'INVALID_CHARACTERS',
}

const SELECT_VALUE_SEPARATOR = '-';

const SelectInputValues = {
  SELECT_OPTION: `SELECT_OPTION${SELECT_VALUE_SEPARATOR}SELECT_OPTION`,
  PAYMENT_ARRANGEMENTS_DEFERRALS: `PAYMENT_ARRANGEMENTS_DEFERRALS${SELECT_VALUE_SEPARATOR}012f1000000LJK8`,
  PAYOUT_DISCHARGES: `PAYOUT_DISCHARGES${SELECT_VALUE_SEPARATOR}012f1000000LJKB`,
  INSURANCE_CLAIM_POLICY: `INSURANCE_CLAIM_POLICY${SELECT_VALUE_SEPARATOR}012f1000000LJKA`,
  PAYMENT_DATE_CHANGE: `PAYMENT_DATE_CHANGE${SELECT_VALUE_SEPARATOR}012f1000000LJK9`,
  PAYMENT_FREQUENCY_CHANGE: `PAYMENT_FREQUENCY_CHANGE${SELECT_VALUE_SEPARATOR}012f1000000LJK9`,
  BANK_INFORMATION_PREPAYMENT_OPTIONS: `BANK_INFORMATION_PREPAYMENT_OPTIONS${SELECT_VALUE_SEPARATOR}012f1000000LJK9`,
  RENEWAL: `RENEWAL${SELECT_VALUE_SEPARATOR}012f1000000LJKD`,
  INTERNAL_REFINANCE: `INTERNAL_REFINANCE${SELECT_VALUE_SEPARATOR}0125o0000004Imy`,
  PROPERTY_TAXES: `PROPERTY_TAXES${SELECT_VALUE_SEPARATOR}012f1000000LJKC`,
};

const ContactUs: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  if (!process.env.GATSBY_WEB_TO_CASE_SALESFORCE_URL && process.env.NODE_ENV === 'development') {
    console.error(
      `Missing env variable: 'GATSBY_WEB_TO_CASE_SALESFORCE_URL', your form submission will not work. Refer to '/env.template' file to learn how to set it`
    );
  }

  if (!process.env.GATSBY_WEB_TO_CASE_SALESFORCE_ORG_ID && process.env.NODE_ENV === 'development') {
    console.error(
      `Missing env variable: 'GATSBY_WEB_TO_CASE_SALESFORCE_ORG_ID', your form submission will not work. Refer to '/env.template' file to learn how to set it`
    );
  }

  const inquiryOptions: SelectOption[] = [
    {
      label: t('contactUs.form.inquiryRegarding.options.pleaseSelectOption'),
      value: SelectInputValues.SELECT_OPTION,
      disabled: true,
    },
    {
      label: t('contactUs.form.inquiryRegarding.options.paymentArrangementsDeferrals'),
      value: SelectInputValues.PAYMENT_ARRANGEMENTS_DEFERRALS,
    },
    {
      label: t('contactUs.form.inquiryRegarding.options.payoutsDischarges'),
      value: SelectInputValues.PAYOUT_DISCHARGES,
    },
    {
      label: t('contactUs.form.inquiryRegarding.options.insuranceClaimPolicy'),
      value: SelectInputValues.INSURANCE_CLAIM_POLICY,
    },
    {
      label: t('contactUs.form.inquiryRegarding.options.paymentDateChange'),
      value: SelectInputValues.PAYMENT_DATE_CHANGE,
    },
    {
      label: t('contactUs.form.inquiryRegarding.options.paymentFrequencyChange'),
      value: SelectInputValues.PAYMENT_FREQUENCY_CHANGE,
    },
    {
      label: t('contactUs.form.inquiryRegarding.options.bankInformationPrepaymentOptions'),
      value: SelectInputValues.BANK_INFORMATION_PREPAYMENT_OPTIONS,
    },
    {
      label: t('contactUs.form.inquiryRegarding.options.renewal'),
      value: SelectInputValues.RENEWAL,
    },
    {
      label: t('contactUs.form.inquiryRegarding.options.internalRefinance'),
      value: SelectInputValues.INTERNAL_REFINANCE,
    },
    {
      label: t('contactUs.form.inquiryRegarding.options.propertyTaxes'),
      value: SelectInputValues.PROPERTY_TAXES,
    },
  ];

  const initialContactFormState = {
    name: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    email: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    inquiryRegarding: {
      isInvalid: false,
      validationMessage: '',
      value: inquiryOptions[0].value,
    },
    message: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    reCaptcha: {
      isInvalid: false,
      validationMessage: '',
      value: 'fake',
      ts: '',
    },
  };

  const [areFormValuesInvalid, setAreFormValuesInvalid] = useState(false);
  const [formSubmissionResponse, setFormSubmissionResponse] = useState({
    submitted: false,
    shouldDisplayMessage: false,
  });

  const [contactFormValues, setContactFormValues] = useState(initialContactFormState);
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const reCaptchaTimerRef = useRef('');

  // This is used by Salesforce to verify ReCaptcha token
  const timestamp = () => {
    const response = document.getElementById('g-recaptcha-response');
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    if (response == null || response.value.trim() == '') {
      reCaptchaTimerRef.current = JSON.stringify(new Date().getTime());
    }
  };

  useEffect(() => {
    const interval = setInterval(timestamp, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (formSubmissionResponse.shouldDisplayMessage && formSubmissionResponse.submitted) {
      const message = document.getElementById('success-message');

      if (message) {
        message.scrollIntoView({ block: 'end' });
      }
    }
  }, [formSubmissionResponse]);

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('contactUs.seo.title'),
    },
    page: {
      title: t('contactUs.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-7.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const validateInput = (
    value: string,
    type: 'email' | 'select' | 'string' = 'string'
  ): { valid: boolean; errorType: InputErrorType | null } => {
    if (value.trim()) {
      if (SanitizeHtml(value).length !== value.length) {
        return { valid: false, errorType: InputErrorType.INVALID_CHARACTERS };
      }

      if (type === 'email' && !EmailValidator.validate(value)) {
        return { valid: false, errorType: InputErrorType.INVALID_CHARACTERS };
      }

      if (type === 'select' && value === SelectInputValues.SELECT_OPTION) {
        return { valid: false, errorType: InputErrorType.EMPTY };
      }

      return { valid: true, errorType: null };
    }

    return { valid: false, errorType: InputErrorType.EMPTY };
  };

  const getErrorValidationMessage = (
    inputErrorType: InputErrorType,
    validationMessage: { emptyField: string; invalidCharacters: string }
  ): string => {
    if (inputErrorType === InputErrorType.EMPTY) {
      return validationMessage.emptyField;
    }

    return validationMessage.invalidCharacters;
  };

  const handleFormChange = (value: string, id: string) => {
    switch (id) {
      case 'name':
        setContactFormValues((prevState) => ({
          ...prevState,
          name: {
            ...prevState.name,
            value,
          },
        }));
        break;
      case 'email':
        setContactFormValues((prevState) => ({
          ...prevState,
          email: {
            ...prevState.email,
            value,
          },
        }));
        break;
      case 'inquiryRegarding':
        setContactFormValues((prevState) => ({
          ...prevState,
          inquiryRegarding: {
            ...prevState.inquiryRegarding,
            value,
          },
        }));
        break;
      case 'message':
        setContactFormValues((prevState) => ({
          ...prevState,
          message: {
            ...prevState.message,
            value,
          },
        }));
        break;
      default:
        if (process.env.NODE_ENV === 'development') {
          console.error(
            `${id} does not exist on Contact us form. Please check form on '/src/pages/contact-us/' page`
          );
        }
        break;
    }
  };

  const validateAndParseName = () => {
    const validationResults = validateInput(contactFormValues.name.value);

    const nameState = {
      ...contactFormValues.name,
      validationMessage: '',
      isInvalid: false,
    };

    nameState.isInvalid = !validationResults.valid;

    if (!validationResults.valid && validationResults.errorType) {
      const validationMessage = getErrorValidationMessage(
        validationResults.errorType,
        t('contactUs.form.name.validationMessage', { returnObjects: true })
      );
      nameState.validationMessage = validationMessage;
    }

    return nameState;
  };

  const validateAndParseMessage = () => {
    const validationResults = validateInput(contactFormValues.message.value);

    const messageState = {
      ...contactFormValues.message,
      validationMessage: '',
      isInvalid: false,
    };

    messageState.isInvalid = !validationResults.valid;

    if (!validationResults.valid && validationResults.errorType) {
      const validationMessage = getErrorValidationMessage(
        validationResults.errorType,
        t('contactUs.form.message.validationMessage', { returnObjects: true })
      );
      messageState.validationMessage = validationMessage;
    }

    return messageState;
  };

  const validateAndParseEmail = () => {
    const validationResults = validateInput(contactFormValues.email.value, 'email');

    const emailState = {
      ...contactFormValues.email,
      validationMessage: '',
      isInvalid: false,
    };

    emailState.isInvalid = !validationResults.valid;

    if (!validationResults.valid && validationResults.errorType) {
      const validationMessage = getErrorValidationMessage(
        validationResults.errorType,
        t('contactUs.form.email.validationMessage', { returnObjects: true })
      );
      emailState.validationMessage = validationMessage;
    }

    return emailState;
  };

  const validateAndParseInquiry = () => {
    const validationResults = validateInput(contactFormValues.inquiryRegarding.value, 'select');

    const inquiryState = {
      ...contactFormValues.inquiryRegarding,
      validationMessage: '',
      isInvalid: false,
    };

    inquiryState.isInvalid = !validationResults.valid;

    if (!validationResults.valid && validationResults.errorType) {
      const validationMessage = getErrorValidationMessage(
        validationResults.errorType,
        t('contactUs.form.inquiryRegarding.validationMessage', { returnObjects: true })
      );
      inquiryState.validationMessage = validationMessage;
    }

    return inquiryState;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.persist();
    let formFieldsValid = true;
    let firstErrorField = '';

    const newNameState = validateAndParseName();

    if (newNameState.isInvalid) {
      formFieldsValid = false;
      firstErrorField = 'name';
    }

    const newEmailState = validateAndParseEmail();

    if (newEmailState.isInvalid) {
      formFieldsValid = false;
      if (!firstErrorField) firstErrorField = 'email';
    }

    const newInquiryState = validateAndParseInquiry();

    if (newInquiryState.isInvalid) {
      formFieldsValid = false;
      if (!firstErrorField) firstErrorField = 'inquiryRegarding';
    }

    const newMessageState = validateAndParseMessage();

    if (newMessageState.isInvalid) {
      formFieldsValid = false;
      if (!firstErrorField) firstErrorField = 'message';
    }

    const reCaptchaState = contactFormValues.reCaptcha;

    if (!reCaptchaState.value) {
      formFieldsValid = false;
      if (!firstErrorField) firstErrorField = 'recaptcha';
      reCaptchaState.isInvalid = true;
    }

    setContactFormValues((prevState) => ({
      ...prevState,
      name: {
        ...newNameState,
      },
      email: {
        ...newEmailState,
      },
      inquiryRegarding: {
        ...newInquiryState,
      },
      message: {
        ...newMessageState,
      },
      reCaptcha: {
        ...reCaptchaState,
      },
    }));

    if (!formFieldsValid) {
      setAreFormValuesInvalid(true);

      if (firstErrorField) {
        let inputField;

        if (firstErrorField === 'recaptcha') {
          inputField = document.querySelector('recaptcha-checkbox-border');
        } else {
          inputField = document.getElementById(firstErrorField);
        }

        if (inputField) {
          (inputField as HTMLElement).focus();
        }
      }
    } else {
      setAreFormValuesInvalid(false);

      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }

      // get the first property
      const parsedRecordType = contactFormValues.inquiryRegarding.value.split(
        SELECT_VALUE_SEPARATOR
      )[1];

      const finalObject = {
        debug: process.env.NODE_ENV === 'development' ? 1 : 0,
        orgid: process.env.GATSBY_WEB_TO_CASE_SALESFORCE_ORG_ID,
        name: contactFormValues.name.value,
        email: contactFormValues.email.value,
        recordType: parsedRecordType,
        subject: inquiryOptions.find((io) => io.value === newInquiryState.value)?.label,
        description: contactFormValues.message.value,
        'g-recaptcha-response': reCaptchaState.value,
        captcha_settings: JSON.stringify({
          keyname: process.env.GATSBY_WEB_TO_CASE_SALESFORCE_CAPTCHA_KEY_NAME,
          fallback: 'true',
          orgId: process.env.GATSBY_WEB_TO_CASE_SALESFORCE_ORG_ID,
          ts: reCaptchaTimerRef.current, // inclusion of time means response was validated
        }),
        submit: 'Submit',
      };

      await fetch(process.env.GATSBY_WEB_TO_CASE_SALESFORCE_URL as RequestInfo, {
        method: 'POST',
        body: qs.stringify(finalObject),
        credentials: 'same-origin',
        mode: 'no-cors',
        // @ts-ignore
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          Accept:
            'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
          Origin: null,
          'Upgrade-Insecure-Requests': 1,
        },
      })
        .then(() => {
          setFormSubmissionResponse({
            submitted: true,
            shouldDisplayMessage: true,
          });

          setContactFormValues(() => initialContactFormState);
        })
        .catch((err) => {
          console.error(`Failed: `, err);
          setFormSubmissionResponse({
            submitted: false,
            shouldDisplayMessage: true,
          });
        });

      return true;
    }
    return false;
  };

  const getCustomerServiceSupportListItems = () => {
    const listItems = t('contactUs.footerBlock.currentCustomers.blocks', {
      returnObjects: true,
    }) as string[];

    return listItems.map((item, i) => {
      return <li key={`${item}-${i}`}>{item}</li>;
    });
  };

  const handleCaptchaOnChange = (value: any) => {
    setContactFormValues((prevState) => ({
      ...prevState,
      reCaptcha: {
        ...prevState.reCaptcha,
        isInvalid: false,
        value,
      },
    }));
  };

  const getForm = () => {
    return (
      <form onSubmit={handleSubmit} className="ContactUs__generic-gap">
        <Input
          id="name"
          isInvalid={contactFormValues.name.isInvalid}
          validationMessage={contactFormValues.name.validationMessage}
          options={{
            type: InputType.TEXT,
            label: t('contactUs.form.name.label'),
            inputProps: {
              name: 'name',
              value: contactFormValues.name.value,
            },
          }}
          required
          onChange={handleFormChange}
        />
        <Input
          id="email"
          isInvalid={contactFormValues.email.isInvalid}
          validationMessage={contactFormValues.email.validationMessage}
          options={{
            type: InputType.TEXT,
            label: t('contactUs.form.email.label'),
            inputProps: {
              name: 'email',
              value: contactFormValues.email.value,
            },
          }}
          required
          onChange={handleFormChange}
        />
        <NativeSelect
          id="inquiryRegarding"
          isInvalid={contactFormValues.inquiryRegarding.isInvalid}
          validationMessage={contactFormValues.inquiryRegarding.validationMessage}
          options={{
            label: t('contactUs.form.inquiryRegarding.label'),
            selectProps: {
              value: contactFormValues.inquiryRegarding.value,
              name: 'recordType',
            },
          }}
          required
          selectOptions={inquiryOptions}
          onChange={handleFormChange}
        />
        <TextArea
          id="message"
          isInvalid={contactFormValues.message.isInvalid}
          validationMessage={contactFormValues.message.validationMessage}
          options={{
            label: t('contactUs.form.message.label'),
            textAreaProps: {
              rows: 13,
              cols: 40,
              name: 'description',
              value: contactFormValues.message.value,
            },
          }}
          required
          onChange={handleFormChange}
        />
        <div className="ContactUs__recaptcha-wrapper">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.GATSBY_WEB_TO_CASE_SALESFORCE_CAPTCHA_KEY_VALUE || ''}
            onChange={handleCaptchaOnChange}
            hl={activeLocaleTag}
            theme="dark"
          />
        </div>

        {formSubmissionResponse.shouldDisplayMessage && !formSubmissionResponse.submitted && (
          <ErrorMessage className="ContactUs__generic-error-message">
            {t('contactUs.form.submissionValidation.error')}
          </ErrorMessage>
        )}

        {areFormValuesInvalid && (
          <ErrorMessage className="ContactUs__generic-error-message">
            {t('contactUs.form.genericErrorMessage')}
          </ErrorMessage>
        )}

        <Button
          id="contact-submit"
          type="submit"
          iconOptions={{ icon: IconTypes.SEND_MAIL }}
          styleOptions={{ isInline: true }}
        >
          {t('contactUs.form.submitLabel')}
        </Button>
      </form>
    );
  };

  const getFormScreen = () => {
    if (formSubmissionResponse.shouldDisplayMessage) {
      if (formSubmissionResponse.submitted) {
        return (
          <div className="ContactUs__generic-gap" id="success-message">
            <p className="ContactUs__success-message" aria-live="polite">
              {t('contactUs.form.submissionValidation.success')}
            </p>
          </div>
        );
      }
    }

    return getForm();
  };

  return (
    <Layout options={layoutOptions} className="ContactUs">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60 ContactUs__form-section">
            <div>
              <h2 className="h3">{t('contactUs.introBlock.heading')}</h2>
              <p>{t('contactUs.introBlock.description')}</p>
            </div>
            {getFormScreen()}
          </section>
          <aside className="column column-33">
            <PageMenu menu={contactUsMenu} />
            <InfoBlock
              heading={<h3 className="h4">{t('contactUs.phoneBlock.heading')}</h3>}
              icon={IconTypes.PHONE}
            >
              <div className="ContactUs__generic-gap">
                <h4 className="h6">{t('contactUs.phoneBlock.mortgageClientCare.heading')}</h4>
                <p>
                  <Trans t={t} i18nKey="contactUs.phoneBlock.openingHours" />
                </p>
                <p>
                  {t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)}:&nbsp;
                  <ExternalLink
                    href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                      CommonData.tollFreeCustomerServiceNumber.mortgageServicing
                    }`}
                  >
                    {CommonData.tollFreeCustomerServiceNumber.mortgageServicing}
                  </ExternalLink>
                </p>
                <p>
                  T:&nbsp;
                  <ExternalLink
                    href={`tel:${CommonData.telephoneNumber.toronto.first}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:phoneNumber`)} ${
                      CommonData.telephoneNumber.toronto.first
                    }`}
                  >
                    {CommonData.telephoneNumber.toronto.first}
                  </ExternalLink>
                </p>
                <p>
                  F:&nbsp;
                  <ExternalLink
                    href={`tel:${CommonData.faxNumber.complaintsDepartment}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:fax`)} ${
                      CommonData.faxNumber.complaintsDepartment
                    }`}
                  >
                    {CommonData.faxNumber.complaintsDepartment}
                  </ExternalLink>
                </p>
              </div>
              <div className="ContactUs__generic-gap">
                <h4 className="h6">{t('contactUs.phoneBlock.brokerRelated.heading')}</h4>
                <p>
                  <Trans t={t} i18nKey="contactUs.phoneBlock.brokerRelated.openingHours" />
                </p>
                <p>
                  {t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)}:&nbsp;
                  <ExternalLink
                    href={`tel:${CommonData.tollFreeCustomerServiceNumber.brokerRelated}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                      CommonData.tollFreeCustomerServiceNumber.brokerRelated
                    }`}
                  >
                    {CommonData.tollFreeCustomerServiceNumber.brokerRelated}
                  </ExternalLink>
                </p>
              </div>
              <div className="ContactUs__generic-gap">
                <h4 className="h6">{t('contactUs.phoneBlock.depositRelated.heading')}</h4>
                <p>
                  {t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)}:&nbsp;
                  <ExternalLink
                    href={`tel:${CommonData.tollFreeCustomerServiceNumber.depositServices}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                      CommonData.tollFreeCustomerServiceNumber.depositServices
                    }`}
                  >
                    {CommonData.tollFreeCustomerServiceNumber.depositServices}
                  </ExternalLink>
                </p>
                {/* <p>
                  F:&nbsp;
                  <ExternalLink href={`tel:${CommonData.faxNumber.gic}`}>
                    {CommonData.faxNumber.gic}
                  </ExternalLink>
                </p> */}
              </div>
            </InfoBlock>
            <p className="small">{t('contactUs.footerNote')}</p>
          </aside>
        </div>

        <div className="row row-wrap ContactUs__generic-gap ContactUs__footer-blocks-wrapper">
          <div className="column column-25">
            <InfoBlock
              heading={<h3 className="h5">{t('contactUs.footerBlock.mortgagePayouts.heading')}</h3>}
              icon={IconTypes.DOLLAR}
              buttonOptions={{
                to: CommonData.websites.payout[activeLocaleTag],
                label: t('contactUs.footerBlock.mortgagePayouts.buttonLabel'),
                iconOptions: {
                  icon: IconTypes.ARROW_NEXT,
                },
                styleOptions: {
                  isInline: true,
                  size: ButtonLinkSize.SMALL,
                },
                linkOptions: {
                  isExternalLink: true,
                },
              }}
            >
              <p>{t('contactUs.footerBlock.mortgagePayouts.description')}</p>
            </InfoBlock>
          </div>
          <div className="column column-25">
            <InfoBlock
              className="ContactUs__reduce-icon-size"
              heading={
                <h3 className="h5">{t('contactUs.footerBlock.complaintHandling.heading')}</h3>
              }
              icon={IconTypes.DOCUMENT_WARNING}
              buttonOptions={{
                to: getPathFromRouteKey(RouteKeys.CONTACT_US.COMPLAINTS_HANDLING),
                label: t('contactUs.footerBlock.complaintHandling.buttonLabel'),
                iconOptions: {
                  icon: IconTypes.ARROW_NEXT,
                },
                styleOptions: {
                  isInline: true,
                  size: ButtonLinkSize.SMALL,
                },
              }}
            >
              <p>{t('contactUs.footerBlock.complaintHandling.description')}</p>
            </InfoBlock>
          </div>
          <div className="column column-25">
            <InfoBlock
              className="ContactUs__reduce-icon-size ContactUs__customer-service-block"
              heading={
                <h3 className="h5">{t('contactUs.footerBlock.currentCustomers.heading')}</h3>
              }
              icon={IconTypes.RESCUE_RING}
              buttonOptions={{
                to: getPathFromRouteKey(RouteKeys.CONTACT_US.CUSTOMER_SERVICE_SUPPORT),
                label: t('contactUs.footerBlock.currentCustomers.buttonLabel'),
                iconOptions: {
                  icon: IconTypes.ARROW_NEXT,
                },
                styleOptions: {
                  isInline: true,
                  size: ButtonLinkSize.SMALL,
                },
              }}
            >
              <ul>
                {getCustomerServiceSupportListItems()}
                <li>
                  <Link to={getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.NEXTJOB_PROGRAM)}>
                    {t('contactUs.footerBlock.currentCustomers.links.nextJob')}
                  </Link>
                </li>
              </ul>
            </InfoBlock>
          </div>
          <div className="row row-wrap">
            <div className="column column-25">
              <InfoBlock
                heading={<h3 className="h5">{t('contactUs.footerBlock.mailCourier.heading')}</h3>}
                icon={IconTypes.MAIL}
              >
                <p>{t('contactUs.footerBlock.mailCourier.description')}</p>
                <p>
                  <b>{t(`${LocaleNameSpaceKeys.COMMON}:haventreeBank`)}</b>
                  <br />
                  <b>{t(`${LocaleNameSpaceKeys.COMMON}:poAddresses.toronto`)}</b>
                </p>
              </InfoBlock>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CONTACT_US)(ContactUs);
